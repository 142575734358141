/*
 * dateTimeUtils.tsx
 * General utility of date and time related functions.
 */

import i18n from "internationalization/i18n";

// Converts the backend datetime fields to the frontend date/time fields to be used to show errors in these fields
export const mapDateTimeFieldToFrontend = (fieldName: string): string[] => {
    // Errors in start_datetime links to the start_date and start_time fields
    if (fieldName === 'start_datetime') {
        return ['start_date'];
    }

    // Errors in end_datetime links to the end_date and end_time fields
    if (fieldName === 'end_datetime') {
        return ['end_date'];
    }

    return [fieldName];
};

// Converts a 24 hour time value into 12-hour time (for example: '14:30' to '2:30 PM')
export const convert24HourTo12HourFormat = (time: string): string => {
    // Get the hours and minutes from the field
    const [hours, minutes] = time.split(':');

    // Convert backend time to AM/PM format
    let hour = parseInt(hours, 10);
    const period = hour < 12 ? 'AM' : 'PM';

    if (hour > 12) hour -= 12;
    if (hour === 0) hour = 12;

    // Return time in 12-hour format
    return `${hour}:${minutes} ${period}`;
};

// Convert 12-hour time format (AM/PM) to 24-hour format
export const convertTo24HourFormat = (value: string, is12HourFormat?: boolean): string => {
    if (!is12HourFormat) {
        return value;
    }

    const parts = value.split(' ');
    const timeParts = parts[0].split(':');
    let hour = parseInt(timeParts[0], 10);
    const minute = timeParts[1];
    
    if (parts[1] === "PM" && hour !== 12) {
        hour += 12;
    } else if (parts[1] === "AM" && hour === 12) {
        hour = 0;
    }
    
    return `${hour.toString().padStart(2, '0')}:${minute}`;
};

// Formats a backend date string into a localized date with written out month (e.g.: September 11, 2024)
export const formatDateString = (dateString: string, userLocale: string): string => {

    // Split the data string in year, month and day
    const [year, month, day] = dateString.split('-').map(Number);

    // Create a new date object. Let the month start on 0
    const dateObj = new Date(year, month - 1, day);

    // Format the date based on the current user locale
    const formattedDate = new Intl.DateTimeFormat(userLocale, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    }).format(dateObj);

    return formattedDate
};

// Generate ISO weeknumbers
export function generateWeekNumber(date: Date, weekStartsOnSunday?: boolean): number {
    let dateObject = new Date(date.getTime());
    dateObject.setHours(0, 0, 0, 0);
    
    // Adjust for nearest thursday in ISO week date rule, accounting for start of the week
    let dayOfWeek = dateObject.getDay();
    
    if (weekStartsOnSunday) {
        dateObject.setDate(dateObject.getDate() + 4 - (dayOfWeek || 7)); // Shift to nearest thursday
    } else {
        dateObject.setDate(dateObject.getDate() + 3 - (dayOfWeek + 6) % 7); // Shift to nearest thursday
    }
    
    // Set reference to january 4, always in the first ISO week
    let jan4 = new Date(dateObject.getFullYear(), 0, 4);
    let jan4DayOfWeek = jan4.getDay();
    
    // Adjust jan4 for the start of the week
    if (weekStartsOnSunday) {
        jan4DayOfWeek = (jan4DayOfWeek === 0) ? 7 : jan4DayOfWeek;
    } else {
        jan4DayOfWeek = (jan4DayOfWeek + 6) % 7; // monday = 0, sunday = 6
    }
    
    // Calculate days between the adjusted date and january 4
    let daysBetween = (dateObject.getTime() - jan4.getTime()) / 86400000;
    
    // Calculate the right ISO week number, considering week starts on sunday
    return Math.ceil((daysBetween + jan4DayOfWeek + 1) / 7);
};

// Validates the start and end date. Sets the start date as end date when only an end time is entered and no end date. In this way the end time is on the same day
export const startDateEndTime = (updatedData: any, data: any): { adjustedUpdatedData: any, errors: { endDate?: string, endTime?: string } } => {
    let errors = {
        endDate: '',
        endTime: ''
    };

    // Get the end datetime from the updated data
    const endDateTime = updatedData["end_datetime"];

    // Get the start datetime from the updated data or existing fetched server data
    const startDateTime = updatedData["start_datetime"] || data["start_datetime"];

    // Check if end_datetime starts with "T" followed by the time in HH:MM:SS format
    if (endDateTime && /^T(\d{2}:\d{2}:\d{2})/.test(endDateTime)) {
        const startDatePart = startDateTime.split('T')[0];
        updatedData["end_datetime"] = `${startDatePart}${endDateTime}`;
    }

    if (startDateTime && endDateTime) {
        const startDate = new Date(startDateTime);
        const endDate = new Date(endDateTime);
    
        // If both start date and end date are on the same date, but the end time is before the start time
        if (startDate.toDateString() === endDate.toDateString()) {
            if (endDate.getTime() < startDate.getTime()) {
                errors.endTime = "date_time.alert.end_time_before_start_time_message";
            }
        // If end date is earlier than start date
        } else if (endDate < startDate) {
            errors.endDate = "date_time.alert.end_date_before_start_date_message";
        }
    }

    return { adjustedUpdatedData: updatedData, errors };
};

// Formats the date for timeline items
export const formatTimelineDate = (inputDate: Date | string, whole_day: boolean) => {
    // Get the current date and time
    const now = new Date();

    // Create date objects for today, tomorrow and yesterday
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

    // Convert the item's inputted date to a JavaScript date object
    const itemDate = new Date(inputDate);

    // Check if the item date corresponds to today, tomorrow or yesterday
    const isToday = itemDate >= today && itemDate < tomorrow;
    const isTomorrow = itemDate >= tomorrow && itemDate < new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2);
    const isYesterday = itemDate >= yesterday && itemDate < today;

    // Default date string format (for example, "12 January")
    let dateString = `${itemDate.getDate()} ${itemDate.toLocaleString('default', { month: 'long' })}`;

    // Update the date string if it's today, tomorrow or yesterday
    if (isToday) dateString = i18n.t('date_time.relative_days.today');
    else if (isTomorrow) dateString = i18n.t('date_time.relative_days.tomorrow');
    else if (isYesterday) dateString = i18n.t('date_time.relative_days.yesterday');

    // Create a time string if task is not for the whole day (for example 'at 15:05')
    const timeString = !whole_day && (itemDate.getHours() || itemDate.getMinutes()) 
        ? ` at ${itemDate.getHours()}:${itemDate.getMinutes() < 10 ? '0' : ''}${itemDate.getMinutes()}`
        : '';

    // Combine the date and time strings (for example: 'Today at 15:05' or '19 March at 9:00')
    return `${dateString}${timeString}`;
};

// Determine the classname of overdue tasks or tasks of today
export const determineTaskDateClass = (inputDate: Date | string, isChecked: boolean) => {
    // Get the current date and time
    const now = new Date();

    // Convert the item's inputted date to a JavaScript date object
    const itemDate = new Date(inputDate);

    // Create date object for today and check if the item date corresponds to today
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const isToday = itemDate >= today && itemDate < new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

    if (itemDate < new Date() && !isChecked) {
        return i18n.t('timeline.task.general.overdue_label');
    } else if (isToday && !isChecked) {
        return i18n.t('timeline.task.general.today_label');
    } else {
        return '';
    }
};
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { useFetchData } from 'services/api/useFetchData';
import { statusFeatureConfig, widgetConfig } from './dashboardConfig';
import { getPeriodDates } from './getPeriodDates';
import { fetchData } from 'services/api/fetchData';
import { fetchWidgetData } from 'components/widgets/fetchWidgetData';
import { CombinedWidgetDataType } from 'types/WidgetTypes';
import { ReleaseType } from './releases/ReleaseTypes';
import Greeting from './Greeting';
import BaseWidget from 'components/widgets/BaseWidget';
import WorkflowBar, { FeatureStatusesType } from './WorkflowBar';
import ToDoList from './ToDoList';
import ReleaseCard from 'components/detailcards/ReleaseCard';
import '../../style/scss/dashboard.scss';

const Dashboard: React.FC = () => {
    const { t } = useTranslation();
    const { allowedFeatures } = useAuthContext();
    const hasRightCheck = useAllowedRight;
    const { response: fetchedReleases, loading: releasesLoading } = useFetchData({ apiUrl: 'get_latest_release_list' });    
    const [widgetPeriod, setWidgetPeriod] = useState<'week' | 'month'>('week');
    const periodDates = getPeriodDates(widgetPeriod);
    const [featureStatuses, setFeatureStatuses] = useState<FeatureStatusesType>({});
    const [widgetData, setWidgetData] = useState<CombinedWidgetDataType>({});
    const [widgetLoading, setWidgetLoading] = useState<boolean>(true);
    const [releases, setReleases] = useState<ReleaseType[]>([]);

    // Fetch the dashboard data
    useEffect(() => {
        // Fetch the feature statuses data
        const fetchFeatureStatuses = async () => {

            // New data object to store the fetched data inside
            const newFeatureStatusesData: FeatureStatusesType = {};

            // Loop through the status features config
            await Promise.all(Object.keys(statusFeatureConfig).map(async (featureKey) => {
                const allowedFeatureKey = featureKey as keyof typeof statusFeatureConfig;

                // Only fetch the data if the feature exists in the allowed features of the user
                if (allowedFeatures.includes(statusFeatureConfig[allowedFeatureKey])) {

                    // Get the current period from the period dates function
                    const { currentPeriod } = periodDates;

                    // Fetch the data
                    const response = await fetchData({ apiUrl: `get_current_${featureKey}_statuses/?from=${currentPeriod.from}&to=${currentPeriod.to}` });
                    
                    // Save the fetched data inside the new feature data statuses object
                    newFeatureStatusesData[featureKey] = response || [];
                }
            }));

            // Save the feature statuses data in the state
            setFeatureStatuses(newFeatureStatusesData);
        };

        // Fetch the default widget and optionally the dimension widget data
        const fetchDataForAllWidgets = async () => {

            // Execute the fetches by the generic fetch widget data function
            const allWidgetsData = await fetchWidgetData(periodDates);

            // Save the widget data in the state
            setWidgetData(allWidgetsData);

            // Set widget loading to false to hide the loader
            setWidgetLoading(false);
        };

        // Execute both fetch functions
        fetchFeatureStatuses();
        fetchDataForAllWidgets();
    }, [allowedFeatures]);

    // Set the last releases
    useEffect(() => {
        if (fetchedReleases) {
            setReleases(fetchedReleases.results);
        };
    }, [fetchedReleases]);

    // Only show the releases for administrators
    const showReleases = hasRightCheck('change_settings');
    const todosSectionClassName = showReleases ? 'todos-section releases' : 'todos-section';

    return (
        <div className='container-filled'>
            <div className='dashboard'>
                <div className='greeting'>
                    <h2>
                        <Greeting />
                    </h2>
                </div>
                <div className='widgets-section'>
                    <h5>{t('dashboard.general.highlights_title')}</h5>
                    <div className='widgets-row'>
                        {widgetConfig.map(widget => (
                            <BaseWidget 
                                period={widgetPeriod} 
                                data={widgetData[widget.key]} 
                                loading={widgetLoading}
                                {...widget} 
                            />
                        ))}
                    </div>
                </div>
                <WorkflowBar featureStatuses={featureStatuses} />
                <div className={todosSectionClassName}>
                    <ToDoList featureStatuses={featureStatuses} />
                    {showReleases && 
                        <ReleaseCard
                            releases={releases}
                            loading={releasesLoading === 'show-loader' ?? null}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
import React, { useState, useEffect } from 'react';
import { Button } from 'types/ButtonTypes';
import { useTranslation } from 'react-i18next';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import styles from '../../style/scss/button.module.scss';

const PrimaryButton: React.FC<Button> = ({ 
    onClick, 
    label, 
    size = 'medium', 
    loading = false, 
    type = 'button', 
    onlyViewRestriction,
    customClass
}) => {
    const { t } = useTranslation();

    // Make the button invisible if it is restricted for only_view users
    const userHasOnlyViewRight = !useAllowedRight('only_view')
    const isVisible = onlyViewRestriction ? userHasOnlyViewRight : true;

    // Set the size, button and text loading classes
    const buttonSizeClass = styles[`button-${size}`];
    const buttonLoadingClass = loading ? styles['button-loading'] : '';
    const textLoadingClass = loading ? styles['text-hidden'] : '';

    // Give the loader icon a delay, so it looks smoother
    const [showLoaderWithDelay, setShowLoaderWithDelay] = useState(false);
    useEffect(() => {
        let timer: number;
        if (loading) {
            timer = window.setTimeout(() => setShowLoaderWithDelay(true), 150)
        } else {
            setShowLoaderWithDelay(false);
        }
        return () => clearTimeout(timer);
    }, [loading]);

    return (
        isVisible ? (
            <button type={type}
                    onClick={onClick}
                    className={`${styles.button} ${buttonSizeClass} ${styles['button-primary']} ${customClass} ${buttonLoadingClass}`}
                    disabled={loading}>
                <span className={`${styles['button-text']} ${textLoadingClass}`}>
                    {t(label)}
                </span>
                {showLoaderWithDelay && 
                    <div className={styles['loader-container']}>
                        <div className={styles['button-loader']}></div>
                    </div>
                }
            </button>
        ) : null
    );
};

export default PrimaryButton;
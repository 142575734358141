import React, { useEffect, useState } from 'react';
import { WorkflowStatus } from '../../views/settings/workflows/WorkflowWizard';
import { useTranslation } from 'react-i18next';
import '../../style/scss/status-bar.scss';

interface StatusBarProps {
    statuses: WorkflowStatus[];
    currentStatus?: number | null;
    workflowType: 'deal' | 'proposal' | 'job' | 'project' | 'invoice';
}

interface StatusBarStep {
    stepNumber: number;
    statuses: WorkflowStatus[];
    activeClassName?: string;
}

// TODO: refactor this in the future by Statuses and SubSteps
const mapConditionsToStatusSteps: Record<string, number> = {
    'job_created': 1,
    'one_open_purchase': 1,
    'all_purchases_delivered': 1,
    'one_reserved_appointment': 2,
    'scheduled_appointment': 2,
    'running_travel_timer': 3,
    'running_work_timer': 3,
    'incomplete': 4,
    'completed': 5,
    'finished': 6,
};

const StatusBar: React.FC<StatusBarProps> = ({ statuses, currentStatus }) => {
    const { t } = useTranslation();
    const [statusBarSteps, setStatusBarSteps] = useState<StatusBarStep[]>([]); // TODO: refactor this in the future by Statuses and SubSteps

    // Group the statuses based on its condition and the steps
    useEffect(() => {
        const groupStatusesByStep: StatusBarStep[] = [];
        let currentCondition: string = '';

        statuses.forEach(status => {
            // Set the current condition to the condition of the current status
            if (status.id === currentStatus) {
                currentCondition = status.condition;
            }

            const stepNumber = mapConditionsToStatusSteps[status.condition] || 1;
            const existingStep = groupStatusesByStep.find(s => s.stepNumber === stepNumber);

            if (existingStep) {
                existingStep.statuses.push(status);
            } else {
                groupStatusesByStep.push({ 
                    stepNumber, 
                    statuses: [status] });
            }
        });

        // Assign active class name to all relevant steps
        const maxStep = mapConditionsToStatusSteps[currentCondition] || 0;
        groupStatusesByStep.forEach(step => {
            if (step.stepNumber <= maxStep) {
                step.activeClassName = currentCondition;
            }
        });

        setStatusBarSteps(groupStatusesByStep);
    }, [statuses, currentStatus]);

    return (
        <ol className='status-bar'>
            {statusBarSteps.map((step) => (
                <li key={step.stepNumber} 
                    className={`status-step ${step.activeClassName || ''}`}>
                    {step.statuses.map((status) => (
                        status.id === currentStatus ? <span className='status-name' key={status.id}>{t(`workflow.default_status_names.${status.name}`)}</span> : null
                    ))}                
                </li>
            ))}
        </ol>
    );
};

export default StatusBar;
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import ReactQuill from 'react-quill';
import CustomQuillEditor from '../fields/CustomQuillEditor';
import FormFieldContext from '../FormFieldContext';
import 'react-quill/dist/quill.snow.css';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/quill-customized.scss';

interface TextareaInputProps {
    id?: string;
    name: string;
    value: string;
    placeholder?: string;
    autoFocus?: boolean;
    rows?: number;
    isNote?: boolean;
    disabled?: boolean;
    enableStyling?: 'simple' | 'extended' | 'comprehensive';
    showToolbarInitially?: boolean;
    viewInEditMode?: boolean;
    onChange: (value: string | React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextareaInput: React.FC<TextareaInputProps> = ({
    id, name, value, placeholder, autoFocus, rows = 2, isNote, disabled, enableStyling, 
    showToolbarInitially, viewInEditMode, onChange
}) => {
    const { t } = useTranslation();
    const { errorMessages } = useGlobalContext();
    const { editing } = useContext(FormFieldContext);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const quillRef = useRef<ReactQuill>(null);
    const [isFocused, setIsFocused] = useState(false);
    const textareaLineHeight = 1.2;
    const textareaPadding = 0.4;
    const baseFontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
    
    // Calculates the textarea height based on the given rows
    const setTextAreaHeight = (elem: HTMLElement) => {
        // Calculate the default min height of 2 rows (or more if given) in rem
        const minHeight = (rows * textareaLineHeight) + (2 * textareaPadding);

        // Calculate the default height in pixels
        const minHeightPx = minHeight * baseFontSize;

        // Set the default height to the calculated min height
        elem.style.minHeight = `${minHeight}rem`;

        if (elem.scrollHeight > minHeightPx) {
            elem.style.height = `${elem.scrollHeight / baseFontSize}rem`;
        }
    };

    // Sets and grows the height of the field
    useEffect(() => {
        // For the normal textarea
        if (textareaRef.current) {
            setTextAreaHeight(textareaRef.current);
        }

        // For the Quill styled textarea editor
        if (quillRef.current) {
            const editor = quillRef.current.getEditor();
            const editorElem = editor.root as HTMLElement;
            setTextAreaHeight(editorElem);
        }
    }, [value, rows]);

    // Makes the text area focused after one click when its in view in edit mode 
    useEffect(() => {
        if (editing && viewInEditMode) {
            if (enableStyling && quillRef.current) {
                const editor = quillRef.current.getEditor();
                editor.focus();
            } else if (textareaRef.current) {
                textareaRef.current.focus();
            }
        }
    }, [editing, viewInEditMode, enableStyling]);

    // Get the error message from the errorState
    const errorMessage = errorMessages[name];

    return (
        <div className="textarea-input">
            {enableStyling ? (
                <CustomQuillEditor 
                    ref={quillRef}
                    value={value}
                    enableStyling={enableStyling}
                    showToolbarInitially={showToolbarInitially}
                    onChange={value => onChange(value)}
                    placeholder={placeholder}
                    className={`${errorMessage ? 'is-invalid' : ''} ${isNote ? 'note-field' : ''} ${isFocused ? 'focused' : ''}`}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
            ) : (
                <textarea
                    ref={textareaRef}
                    id={id}
                    name={name}
                    placeholder={t(placeholder)}
                    disabled={disabled}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    className={`${errorMessage ? 'is-invalid' : ''} ${isNote ? 'note-field' : ''}`}
                    autoFocus={autoFocus}
                />
            )}
        </div>
    )
}

export default TextareaInput;